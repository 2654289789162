const primary = {
  50: '#FFF1F1',
  100: '#FFE0E1',
  200: '#FFC6C8',
  300: '#FF9EA1',
  400: '#FF666B',
  500: '#FE353B',
  600: '#EC161C',
  700: '#D91016',
  800: '#A41014',
  900: '#871518',
};

const secondary = {
  50: '#FFFEE7',
  100: '#FFFEC1',
  200: '#FFF886',
  300: '#FFEC41',
  400: '#FFDB0D',
  500: '#FFCC00',
  600: '#D19500',
  700: '#A66A02',
  800: '#89530A',
  900: '#74430F',
};

const royalBlue = {
  50: '#F0F7FF',
  100: '#E0EFFE',
  200: '#B2D7FD',
  300: '#84BFFC',
  400: '#3797F9',
  500: '#0675E7',
  600: '#055EBA',
  700: '#04488E',
  800: '#03366B',
  900: '#022447',
};

const gray = {
  50: '#F7F6FA',
  100: '#F2F0F4',
  200: '#E3E2E6',
  300: '#C7C6CA',
  400: '#ACABAF',
  500: '#919094',
  600: '#747477',
  700: '#5E5E62',
  800: '#46464A',
  900: '#303034',
};

const colors = {
  primary: {
    DEFAULT: primary[700],
    ...primary,
  },
  secondary: {
    DEFAULT: secondary[500],
    ...secondary,
  },
  'royal-blue': {
    DEFAULT: royalBlue[700],
    ...royalBlue,
  },
  gray: {
    DEFAULT: gray[600],
    ...gray,
  },
};

module.exports = colors;
