const typographyPlugin = require('tailwind-utils/typography.plugin');

module.exports = (typographyConfig) => (theme) => {
  const typograph = (name) => typographyPlugin.getUtilities(theme, typographyConfig)[`.text-${name}`];

  return {
    DEFAULT: {
      css: {
        '--tw-prose-body': theme('colors.black'),
        '--tw-prose-headings': theme('colors.black'),
        '--tw-prose-links': theme('colors.primary.700'),
        '--tw-prose-bullets': theme('colors.black'),
        '--tw-prose-counters': theme('colors.black'),

        'max-width': '100%',

        ...typograph('body-md'),

        h1: {
          ...typograph('headline-md'),
          marginTop: theme('spacing[4.5]'),
          marginBottom: theme('spacing[3.5]'),

          '@media screen(sm)': typograph('headline-lg'),
        },

        h2: {
          ...typograph('headline-sm'),
          marginTop: theme('spacing[4.5]'),
          marginBottom: theme('spacing[3.5]'),

          '@media screen(sm)': typograph('headline-md'),
        },

        h3: {
          ...typograph('headline-xs'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[2.5]'),

          '@media screen(sm)': typograph('headline-sm'),
        },

        h4: {
          ...typograph('headline-2xs'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[2.5]'),

          '@media screen(sm)': typograph('headline-xs'),
        },

        h5: {
          ...typograph('headline-3xs'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[2.5]'),

          '@media screen(sm)': typograph('headline-2xs'),
        },

        h6: {
          ...typograph('headline-3xs'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[2.5]'),
        },

        p: {
          ...typograph('body-md'),
          marginTop: theme('spacing[2.5]'),
          marginBottom: theme('spacing[2.5]'),
        },

        a: {
          textDecoration: 'underline',

          '&:hover': {
            color: theme('colors.primary.600'),
          },

          '&:visited': {
            color: theme('colors.primary.800'),
          },
        },

        ol: {
          marginBottom: theme('spacing[3]'),
        },

        ul: {
          marginBottom: theme('spacing[3]'),
        },

        li: {
          ...typograph('body-md'),
          marginBottom: 0,
          paddingLeft: 0,
        },
      },
    },

    factbox: {
      css: {
        ...typograph('body-sm'),

        p: typograph('body-sm'),

        li: typograph('body-sm'),
      },
    },

    coverbox: {
      css: {
        ...typograph('body-sm'),

        p: typograph('body-sm'),

        li: typograph('body-sm'),
      },
    },
  };
};
