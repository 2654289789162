const headline = {
  '3xl': {
    font: 'primary',
    size: '66px',
    weight: 'bold',
    leading: '1.2',
    tracking: '-0.02em',
  },
  '2xl': {
    font: 'primary',
    size: '46px',
    weight: 'bold',
    leading: '1.2',
    tracking: '-0.02em',
  },
  xl: {
    font: 'primary',
    size: '36px',
    weight: 'bold',
    leading: '1.2',
    tracking: '-0.02em',
  },
  lg: {
    font: 'primary',
    size: '26px',
    weight: 'bold',
    leading: '1.2',
    tracking: '-0.02em',
  },
  md: {
    font: 'primary',
    size: '23px',
    weight: 'bold',
    leading: '1.2',
    tracking: '-0.01em',
  },
  sm: {
    font: 'primary',
    size: '20px',
    weight: 'bold',
    leading: '1.2',
    tracking: '-0.005em',
  },
  xs: {
    font: 'primary',
    size: '18px',
    weight: 'bold',
    leading: '1.2',
  },
  '2xs': {
    font: 'primary',
    size: '16px',
    weight: 'bold',
    leading: '1.2',
  },
  '3xs': {
    font: 'primary',
    size: '14px',
    weight: 'bold',
    leading: '1.2',
  },
};

const preamble = {
  lg: {
    font: 'primary',
    size: '29px',
  },
  md: {
    font: 'primary',
    size: '23px',
  },
  sm: {
    font: 'primary',
    size: '20px',
  },
};

const body = {
  lg: {
    font: 'secondary',
    size: '23px',
    leading: '1.5',
  },
  md: {
    font: 'secondary',
    size: '18px',
    leading: '1.5',
  },
  sm: {
    font: 'secondary',
    size: '16px',
    leading: '1.5',
  },
  xs: {
    font: 'secondary',
    size: '14px',
    leading: '1.2',
  },
};

const quote = {
  md: {
    font: 'secondary',
    size: '23px',
    leading: '1.5',
  },
  sm: {
    font: 'secondary',
    size: '20px',
    leading: '1.5',
  },
};

const input = {
  lg: {
    font: 'tertiary',
    size: '18px',
    leading: '1.2',
  },
  md: {
    font: 'tertiary',
    size: '16px',
    leading: '1.2',
  },
  sm: {
    font: 'tertiary',
    size: '14px',
    leading: '1.2',
  },
  xs: {
    font: 'tertiary',
    size: '11px',
    leading: '1.2',
  },
};

const ui = {
  md: {
    font: 'primary',
    size: '16px',
    leading: '1.2',
  },
  sm: {
    font: 'primary',
    size: '14px',
    leading: '1.2',
  },
};

const typography = {
  headline: {
    DEFAULT: headline.md,
    ...headline,
  },
  preamble: {
    DEFAULT: preamble.md,
    ...preamble,
  },
  body: {
    DEFAULT: body.md,
    ...body,
  },
  quote: {
    DEFAULT: quote.md,
    ...quote,
  },
  input: {
    DEFAULT: input.md,
    ...input,
  },
  ui: {
    DEFAULT: ui.md,
    ...ui,
  },
};

module.exports = typography;
