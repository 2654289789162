import { BrandOcelotConfig } from '@/types/ocelotConfig';

const SvenskdamConfig: BrandOcelotConfig = {
  search: {
    googleCustomSearch: {
      id: '008262495618786222540:gvwmwh11uqm',
    },
    placeholderImage: '/image/svenskdam-logo.svg',
  },
  analytics: {
    brandName: 'svenskdam',
    google: {
      dimensions: {
        page_id: '1',
        mobile_website: '2',
      },
      viewId: 141884522,
      hostname: 'www.svenskdam.se',
    },
    parsely: {
      siteId: 'svenskdam.se',
    },
    tealium: {
      account: 'aller-media-sweden',
      profile: 'main',
      environment: 'prod',
    },
    avo: {
      apiKey: 'blcuGqEsX2nT6VJtqDMv',
    },
    funnelRelay: {
      customerId: 'aller_e7bce_svenskdam',
      scriptSource: 'cdn-magiclinks.trackonomics.net/client/static/v2/aller_e7bce_svenskdam.js',
    },
    tags: ['funnelRelay', 'engagedTime', 'scrollDepthTracker', 'loggedInId', 'metadata', 'webVitals'],
    jwplayer: true,
  },
  features: {
    strossle: {
      enabled: true,
      id: '8eda525e-bc8b-430b-a3f0-9deb8bc5b6f6',
    },
    infinityScrollArticle: {
      enabled: true,
    },
    infinityScrollFront: {
      title: 'FRÅN FÖRSTASIDAN',
    },
    keesingGames: {
      enabled: true,
      customerId: 'svenskdam',
      scriptPath: 'https://web.keesing.com/pub/bundle-loader/bundle-loader.js',
      stylesPath: 'https://web.keesing.com/pub/config/svenskdam/css/custom_client.css',
      frontPagePathname: '/korsord',
    },
    direkt: {
      enabled: true,
      id: 'ifr-direkt-d529f9c1-6de4-46cc-9c8c-8df9b5676f60',
      aId: 'd529f9c1-6de4-46cc-9c8c-8df9b5676f60',
      headline: 'Svensk Dam',
      description: 'Följ vår direktrapportering med nyheter om kungligheterna och kändisarna du älskar här!',
      seoTitle: 'Svensk Dam Direkt – snabba kungliga nyheter',
      seoDescription:
        'Följ Svensk Damtidnings direktrapportering där vi löpande håller dig uppdaterad med snabba nyheter om kungligheter och kändisar!',
    },
    direktBanner: {
      inject: true,
      exclude: {
        labradorPageTypes: ['front'],
      },
    },
    highlightedArticles: {
      inject: true,
      exclude: {
        urls: ['/om-oss', '/kontaktaoss', '/om-cookies', '/nyhetsbrev'],
      },
    },
    trendingMenu: {
      tagPrefixes: ['nytt!'],
    },
  },

  pages: {
    author: {
      description:
        'Här samlar vi Svensk Damtidnings alla reportrar. Reportrarna ger oss en inblick i den kungliga, glammiga världen och det svenska nöjeslivet.',
    },
    direkt: {
      enabled: true,
    },
  },
  seo: {
    schemaMarkup: {
      name: 'Svensk Damtidning',
      foundingDate: '1889-12-17',
      alternateName: ['Svensk Dam'],
      sameAs: [
        'https://sv.wikipedia.org/wiki/Svensk_Damtidning',
        'https://www.wikidata.org/wiki/Q10375375',
        'https://www.facebook.com/svenskdamtidning/',
        'https://x.com/svenskdam',
        'https://www.instagram.com/svenskdamtidning/',
        'https://news.google.com/publications/CAAiEMOO7LLrA0PM4gJfbc3NYiYqFAgKIhDDjuyy6wNDzOICX23NzWIm?hl=sv&gl=SE&ceid=SE%3Asv',
      ],
      publishingPrinciples: 'https://www.svenskdam.se/redaktionell-policy',
      verificationFactCheckingPolicy:
        'https://www.svenskdam.se/redaktionell-policy#:~:text=K%C3%A4llkontroll,i%20fr%C3%A5ga.',
      correctionsPolicy:
        'https://www.svenskdam.se/redaktionell-policy#:~:text=Korregeringar,v%C3%A5r%20ansvarige%20utgivare',
      missionCoveragePrioritesPolicy: 'https://www.svenskdam.se/redaktionell-policy',
      webPages: [
        '/nyhetsbrev',
        '/alla-amnen',
        '/svensk-damtidnings-lasarpanel',
        '/kontaktaoss',
        '/om-oss',
        '/om-cookies',
        '/redaktionell-policy',
        '/vara-skribenter',
      ],
    },
  },
  mobileApp: {
    enabled: true,
    meta: {
      'al:android:url': 'https://www.svenskdam.se?utm_medium=social&utm_campaign=deeplink&utm_source=facebook',
      'al:android:app_name': 'Svenskdam',
      'al:android:package': 'se.aller.svenskdam',
      'al:ios:url': 'https://www.svenskdam.se?utm_medium=social&utm_campaign=deeplink&utm_source=facebook',
      'al:ios:app_store_id': '1530904500',
      'al:ios:app_name': 'Svenskdam',
      'al:web:should_fallback': 'true',
    },
  },
  ads: {
    exceptionPaths: {
      '^korsord$': {
        aboveBody: true,
        extraStartCount: 5000,
        useHeights: false,
      },
      '^korsord/\\S+$': {
        extraStartCount: 5000,
        useHeights: false,
      },
    },
    video: {
      clickToPlay: 'JcHd23Vg',
      autoPlay: 'DfBHqYZx',
      videoPage: 'TvfralFZ',
    },
  },
  cmp: {
    onetrust_id: '3618878f-373e-451c-b350-37dbe0330e69',
  },
  common: {
    redirects: [
      {
        from: '^korsord/',
        to: 'korsord',
      },
    ],
  },
};

export default SvenskdamConfig;
